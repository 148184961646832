import "./App.css"
import Decode from "./views"

function App() {
  return (
    <div className="App">
      <Decode />
    </div>
  )
}

export default App
