import React, { useState } from "react"
import {
  TextField,
  Button,
  Container,
  Typography,
  Box,
  Modal,
} from "@mui/material"

// Main View component with password protection
const MyView = () => {
  const [inputValue, setInputValue] = useState("")
  const [decryptedMessage, setDecryptedMessage] = useState("")
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [passwordInput, setPasswordInput] = useState("")

  const correctPassword = process.env.REACT_APP_PASSWORD
  const decryptionKey = process.env.DECRYPT_KEY

  // Vigenère Decryption function (same as your corrected code)
  function isLetter(str) {
    return str.length === 1 && str.match(/[a-zA-Z]/i)
  }

  function isUpperCase(character) {
    return character === character.toUpperCase()
  }

  function vigenereDecrypt(message, key) {
    let result = ""
    for (let i = 0, j = 0; i < message.length; i++) {
      const c = message.charAt(i)
      if (isLetter(c)) {
        if (isUpperCase(c)) {
          result += String.fromCharCode(
            90 -
              ((25 - (c.charCodeAt(0) - key.toUpperCase().charCodeAt(j))) % 26)
          )
        } else {
          result += String.fromCharCode(
            122 -
              ((25 - (c.charCodeAt(0) - key.toLowerCase().charCodeAt(j))) % 26)
          )
        }
      } else {
        result += c
      }
      j = ++j % key.length
    }
    return result
  }

  const handleInputChange = (event) => {
    setInputValue(event.target.value)
  }

  const handleButtonClick = () => {
    const decrypted = vigenereDecrypt(inputValue, decryptionKey)
    setDecryptedMessage(decrypted)
  }

  const handlePasswordChange = (event) => {
    setPasswordInput(event.target.value)
  }

  const handlePasswordSubmit = () => {
    if (passwordInput === correctPassword) {
      setIsAuthenticated(true)
    } else {
      alert("Not the password LOSER! haha")
    }
  }

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      {/* Password Modal */}
      <Modal open={!isAuthenticated} aria-labelledby="password-modal">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Enter Password
          </Typography>
          <Typography variant="body2" color="textSecondary" mb={2}>
            Hint: Sometimes write wrong is the answer.
          </Typography>
          <TextField
            label="Password"
            variant="outlined"
            value={passwordInput}
            onChange={handlePasswordChange}
            type="password"
            fullWidth
            margin="normal"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handlePasswordSubmit}
          >
            Submit
          </Button>
        </Box>
      </Modal>

      {/* Main Content - Visible only if authenticated */}
      {isAuthenticated && (
        <>
          <Typography variant="h4" component="h1" gutterBottom>
            DECRYPT FOR M
          </Typography>
          <Box display="flex" flexDirection="column" gap={2} mt={2}>
            <TextField
              label="Enter encrypted message"
              variant="outlined"
              value={inputValue}
              onChange={handleInputChange}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleButtonClick}
            >
              Decrypt
            </Button>
            {decryptedMessage && (
              <Typography variant="h6" mt={2}>
                Decrypted Message: {decryptedMessage}
              </Typography>
            )}
          </Box>
        </>
      )}
    </Container>
  )
}

export default MyView
